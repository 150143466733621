<div class="row x-lot-{{lotNumber}} media" data-id="{{id}}">
	<div class="media-left col-lg-2 col-xs-3 col-sm-2 col-md-3 w2-thumb-container">
		<a class="{{showDetailsClass}}" href="#" data-id="{{id}}">
			<img class="w2-inplay-image-thumb text-left img-responsive media-object" src="{{imageThumbSrc}}" alt="{{{lot}}} {{lotNumber}}: {{{lotTitle}}}" onerror="myApp.utils.errors.ImgErrorThumb(this)">
		</a>
	</div>
	<div class="w2-sell-item-body col-lg-10 col-md-9 col-sm-10 col-xs-9">
		<a class="{{showDetailsClass}}" href="#" data-id="{{id}}">
			<h5>
				<strong class="media-heading w2-sell-item-heading">{{{lot}}} {{{lotNumber}}}: </strong>
				<span {{hideGroupUnits}}>({{{itemNumberOfUnits}}} items)</span>
	    	{{{lotTitle}}}
			</h5>
		</a>
		<span class="w2-sell-item-summary">{{{lotSummary}}}</span>
	</div>

	{{#if hasBidActions}}
		<div class="col-md-6 col-sm-8 col-xs-9 text-right pull-right mobile-maxbid">
			<p class="help-block">
				<span class="w2-sell-item-current-bid">{{{currentBidText}}} {{{lotCurrentBid}}}</span>
			</p> 
			<p class="help-block">
				<span class="w2-sell-item-max-bid">{{{currentMaxBidText}}} {{{currentMaxBidAmount}}}</span>
			</p>
			<div class="w2-input-maxbid-container">
				<div class="input-group">
					<input type="text"  id="maxBidInput-{{id}}" class="form-control x-maxbid-input w2-input-maxbid-sell-item"  data-id="{{id}}" placeholder="{{{placeHolderMessage}}}" maxlength="16" />
					<span class="input-group-btn">
						<button type="button" id="maxBidConfirm-{{id}}" class="btn btn-primary x-maxbid-button w2-btn-maxbid-sell-item" data-id="{{id}}">{{{buttonText}}}</button>
					</span>
				</div>
			</div>
		</div>
	{{/if}}
	{{#unless groupButtonHidden}}
		<div class="col-xs-9 text-right pull-right">
			<a type="button" id="toggleGroup_{{id}}" class="x-group-display btn btn-default btn-sm margin-top{{#if groupDisplay}} active{{/if}}" data-id="{{id}}">{{{groupButtonLabel}}} {{#if groupDisplay}}
					<i class="fa fa-angle-up" aria-hidden="true"></i>
				{{else}}
					<i class="fa fa-angle-down" aria-hidden="true"></i>
				{{/if}}
			</a>
		</div>
		{{#if groupDisplay}}
			<div class="col-xs-12">
				<ul id="groupClosedOfItems_{{id}}" class="col-xs-12 w2-sell-items-group-container list-group well margin-top margin-bottom--none {{groupedItems}}"></ul>
			</div>
		{{/if}}
	{{/unless}}
</div>
