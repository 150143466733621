<div class="w2-image-box col-xs-12">
    <div class="w2-no-image text-center" style="display:{{noImageToggle}};">
        <div class="w2-no-image-icon">
            <i class="fa fa-exclamation-triangle"></i>
        </div>
        <div class="w2-no-image-text">
            No image available
        </div> 
    </div>
    <div style="display:{{sliderToggle}};" class="well">
        <ul id="lightSlider" class="third-party--lightSlider">
            {{#each theImages}}
                <li data-thumb="{{imageThumbSrc}}">
                    <a href="{{imageLightboxSrc}}"
                    class="x-open-lightbox"
                    data-toggle="lightbox"
                    data-gallery="multiimages"
                    data-title="Images for Lot- {{lotNumber}}"
                    data-parent="#lightSlider"
                    data-preview="{{imagePreviewSrc}}"><img class="img-responsive" src="{{imagePreviewSrc}}"/>
                    </a>
                    <div class="w2-inplay-lotnumber-overlay label label-primary">Lot: {{lotNumber}}</div>
                </li>
            {{/each}}
        </ul>
    </div>
</div>
