<div class="row margin-top margin-bottom">
	<div class="col-xs-12 grid-container">
		{{#upcomingMarketplace}}
			<div class="market-box grid-item grid-container--item thumbnail">
				<a href="{{../contextPath}}/marketplace?category={{parentCategoryId}}&continent=1,2,6,5,4,3">
					{{#if firstImage.attachmentModel}}
						<img class="grid-item--thumbnail" onerror="myApp.utils.errors.ImgErrorHomeGallery(this)" src="{{#cloudify firstImage.attachmentModel ../dims}}{{/cloudify}}" alt="{{{parentCategoryDescription}}}" />
					{{else}}
						<span class="grid-item--thumbnail img-error-gal-home">{{{../awaitingImageMessage}}}</span>
					{{/if}} 
				</a>
				<div class="caption grid-item--content grid-content">
					<div class="grid-content--info grid-item--grow grid-item--no-shrink">
						<a href="{{../contextPath}}/marketplace?category={{parentCategoryId}}&continent=1,2,6,5,4,3">
							<h4>{{{parentCategoryName}}}</h4>
						</a>
						<h5>({{itemCount}} {{{message}}})</h5>
					</div>
					<div id="grid-content--actions-{{parentCategoryId}}" class="margin-top grid-item--actions grid-item--no-shrink grid-item--no-grow text-center">
						<a id="viewAllLink{{parentCategoryId}}" href="{{../contextPath}}/marketplace?category={{parentCategoryId}}&continent=1,2,6,5,4,3">
							<button id="viewAllButton{{parentCategoryId}}" class="btn btn-primary btn-block">{{{../viewAllLabel}}}</button>
						</a>
					</div>
				</div>
			</div>
		{{/upcomingMarketplace}}
	</div>
</div>
