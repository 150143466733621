	<div class="w2-numeric-choice-view-box col-xs-12 text-center">
		<div class="row">
			<div class="col-xs-12">
				<div class="input-group">
					<input id="wcNumberRequired" type="text"  class="form-control" placeholder="{{{placeHolderMessage}}}" maxlength="16">
						<span class="input-group-btn">
							<button id="wcNumberRequiredSubmit" type="button" class="btn btn-primary" type="button">{{{buttonLabel}}}</button>
					</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12">
				<div class="input-group">
					{{{message}}}
				</div>
			</div>
			<div class="w2-highbid-inplay col-xs-12">
				{{{currentBidMessage}}} <strong>{{{currentFormatted}}}</strong>
			</div>
		</div>
	</div>
