<div class="row margin-top margin-bottom">
	<div class="col-xs-12 grid-container">
		{{#upcomingFeaturedAuctions}}
			<div class="grid-item grid-container--item thumbnail" id="upcomingFeatured_{{auctionId}}">
				<a id="auctionDetailsImageLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
					{{#if firstItem.attachmentModel}}
						<img class="grid-item--thumbnail" src="{{#cloudify firstItem.attachmentModel ../dims}}{{/cloudify}}" alt="{{{firstItem.title}}}">
					{{else}}
						<img class="grid-item--thumbnail" src="#" alt="{{{../awaitingImageMessage}}}">
					{{/if}}
				</a>
				<div class="caption grid-item--content grid-content">
					<div class="grid-content--labels">
						<div class="label label-info">
							{{#if webcastType}}<i class="fa fa-wifi"></i>{{/if}} {{#if timedType}}<i class="fa fa-clock-o"></i>{{/if}} {{{typeMessage}}}
						</div>
					</div>
					<div class="grid-content--info">
						{{#if startTimeString}}<p><strong>{{{startTimeString}}}</strong></p>{{/if}}
						<strong>{{{endsOrStarts}}}</strong>
						<a id="auctionDetailsTitleLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
							<h4>{{{title}}}</h4>
						</a>
						<h5>({{itemCount}} {{{message}}})</h5>
						<h6>{{{location}}}</h6>
					</div>
					<div id="grid-content--actions-{{auctionId}}" class="margin-top grid-item--actions text-center">
						{{#if catalogue}}
							<button id="catalogueAuction{{auctionId}}" class="btn btn-primary btn-block" disabled="true">{{{../catalogueAuctionMessage}}}</button>
						{{else}}
							{{#if registrantStatus}}
								<button id="auctionstatus{{auctionId}}" class="register-forauction btn btn-primary btn-block" disabled="true">{{{lookup ../auctionRegistrantStatusMessage registrantStatus}}}</button>
							{{else}}
								<a class="register-for-auction" id="registerForAuctionFromAuction{{auctionId}}" href="{{../contextPath}}/registerForAuction/{{auctionId}}">
									<button class="btn btn-primary btn-block">{{{../auctionRegistrantStatusMessage.NOT_REGISTERED}}}</button>
								</a>
							{{/if}}
						{{/if}}
					</div>
				</div>
			</div>
		{{/upcomingFeaturedAuctions}}
		{{#upcomingAuctions}}
			<div class="grid-item grid-container--item thumbnail" id="upcomingUnfeatured_{{auctionId}}">
				<a id="auctionDetailsImageLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
					{{#if firstItem.attachmentModel}}
						<img class="grid-item--thumbnail" src="{{#cloudify firstItem.attachmentModel ../dims}}{{/cloudify}}" alt="{{{firstItem.title}}}">
					{{else}}
						<div class="grid-item--thumbnail img-error-gal-home">{{{../awaitingImageMessage}}}</div>
					{{/if}}
				</a>
				<div class="caption grid-item--content grid-content">
					<div class="grid-content--labels">
						<div class="label label-info">
							{{#if webcastType}}<i class="fa fa-wifi"></i>{{/if}} {{#if timedType}}<i class="fa fa-clock-o"></i>{{/if}} {{{typeMessage}}}
						</div>
					</div>
					<div class="grid-content--info grid-item--grow grid-item--no-shrink">
						{{#if startTimeString}}<strong>{{{startTimeString}}}</strong><br />{{/if}}
						<strong>{{{endsOrStarts}}}</strong>
						<a id="auctionDetailsTitleLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
							<h4>{{{title}}}</h4>
						</a>
						<h5>({{itemCount}} {{{message}}})</h5>
						<h6>{{{location}}}</h6>
					</div>
					<div id="grid-content--actions-{{auctionId}}" class="margin-top grid-item--actions grid-item--no-shrink grid-item--no-grow text-center">
						{{#if catalogue}}
							<a id="auctionDetailsCatalogueLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
								<button id="catalogueAuction{{auctionId}}" class="btn btn-primary btn-block">{{{../catalogueAuctionMessage}}}</button>
							</a>
						{{else}}
							{{#if registrantStatus}}
								<button id="auctionstatus{{auctionId}}" class="register-forauction btn btn-primary btn-block" disabled="true">{{{lookup ../auctionRegistrantStatusMessage registrantStatus}}}</button>
							{{else}}
								<a class="register-for-auction" id="registerForAuctionFromAuction{{auctionId}}" href="{{../contextPath}}/registerForAuction/{{auctionId}}">
									<button class="btn btn-primary btn-block">{{{../auctionRegistrantStatusMessage.NOT_REGISTERED}}}</button>
								</a>
							{{/if}}
						{{/if}}
					</div>
				</div>
			</div>
		{{/upcomingAuctions}}
	</div>
</div>
