<div id="inPlayLayout" class="panel-body">
	<div id="emptyView" class="row"></div>
	<div id="itemMessageRegion" class="row"></div>
	<div id="imagesView" class="row"></div>
	<div id="lotTitleRegion" class="row"></div> 
	<div id="videoControlRegion" class="row"></div>
	<div id="messagesView" class="row"></div> 
	<div id="bidView" class="row"></div>
	<div id="maxBidView" class="row"></div>
	<div id="exchangeRatesView" class="row"></div>
</div>	