<div class="row {{inplayRowState}} x-lot-{{lotNumber}}">
	<div class="media-left col-lg-2 col-sm-2 col-xs-3 col-md-3 w2-thumb-container">
		<a class="{{showDetailsClass}}" href="#" data-id="{{id}}">
			<img class="w2-inplay-image-thumb img-responsive media-object" src="{{imageThumbSrc}}" alt="{{{lot}}} {{lotNumber}}: {{{lotTitle}}}"
			 onerror="myApp.utils.errors.ImgErrorThumb(this)">
		</a>
	</div>
	<div class="w2-sell-item-body col-lg-10 col-md-9 col-sm-10 col-xs-9">
		<a class="{{showDetailsClass}}" href="#" data-id="{{id}}">
			<h5>
				<strong class="media-heading w2-sell-item-heading">{{{lot}}} {{lotNumber}}: </strong>
				<span {{hideGroupUnits}}>({{itemNumberOfUnits}} items)</span>
				{{{lotTitle}}}
			</h5>
		</a>
		<span class="w2-closed-item-summary">{{{lotSummary}}}</span>
	</div>
	<div class="col-xs-4 text-right pull-right" style="display:{{toggleBidActions}}">
		{{#if wonLostMessage}}
			<p class="w2-closed-item-won-status help-block">
				{{{wonLostMessage}}}
			</p>
		{{/if}}
		{{#if lotCurrentBid}}
			<p class="w2-sell-item-current-bid help-block">
				{{{currentBidText}}} {{{lotCurrentBid}}}
			</p>
		{{else if currentBidText}}
			<p class="w2-sell-item-current-bid help-block">
				{{{currentBidText}}} {{{lotCurrentBid}}}
			</p>
		{{/if}}
	</div>
	{{#unless groupButtonHidden}}
		<div class="col-xs-9 text-right pull-right">
			<a type="button" id="toggleGroup_{{id}}" class="x-group-display btn btn-default btn-sm margin-top{{#if groupDisplay}} active{{/if}}" data-id="{{id}}">{{{groupButtonLabel}}} {{#if groupDisplay}}
					<i class="fa fa-angle-up" aria-hidden="true"></i>
				{{else}}
					<i class="fa fa-angle-down" aria-hidden="true"></i>
				{{/if}}
			</a>
		</div>
		{{#if groupDisplay}}
			<div class="col-xs-12">
				<ul id="groupClosedOfItems_{{id}}" class="col-xs-12 w2-sell-items-group-container list-group well margin-top margin-bottom--none {{groupedItems}}"></ul>
			</div>
		{{/if}}
	{{/unless}}
</div>