<div class="w2-feed-controls-box text-center">
	{{#if feedControlsMessage.length}}
		<p class="w2-feed-controls-message">{{{feedControlsMessage}}}</p>
	{{/if}}
	{{#if feedHideButtonVisibility}}
		<a id="feedHideButton" class="btn btn-default active">{{{feedHideButtonText}}}</a>
	{{else if feedShowButtonVisibility}}
		<a id="feedShowButton" class="btn btn-default">{{{feedShowButtonText}}}</a>
	{{/if}}
</div>
