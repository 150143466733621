	<div class="w2-bidview-box col-xs-12">
		<div class="row text-center">
			<div class="col-xs-12">
				<button class="x-confirm btn {{buttonClass}} w2-confirm-choice-btn btn-lg" type="button">
					{{{buttonLabel}}}
				</button>
			</div>
			<div class="w2-highbid-inplay col-xs-12">
				{{{currentBidMessage}}} <strong>{{{currentFormatted}}}</strong> ({{{currentUsername}}}) {{{currentBidCount}}}
			</div>
		</div>
	</div>


