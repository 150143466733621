<div class="w2-tabs panel panel-default">
	<div class="panel-body">
		<div class="w2-gotolot text-right hidden-xs form-inline">
			<div class="form-group">
				<label id="goToLotLabel" for="goToLot">{{{gotoLotText}}}: </label>
				<input id="goToLot" class="w2-gotolot-input form-control" type="text" maxlength="5"/>
			</div>
		</div>
	</div>
	<ul id="tabControlView" class="nav nav-tabs" role="tablist">
		<li role="presentation" class="active x-change-tab" data-action="selling"><a id="sellingTabLink" href="#sellingView" role="tab" data-toggle="tab">Selling</a></li>
		<li role="presentation" class="x-change-tab" data-action="closed"><a id="closedTabLink" href="#sellingView" role="tab" data-toggle="tab">Closed</a></li>
		<li class="pull-right"><a id="saleInfoTabLink" class=" x-saleinfo-modal" href="#">Sale Information</a></li>
	</ul>
	<div class="tab-content w2-tabs-scroll">
		<div role="tabpanel" class="active tab-pane w2-sell-view panel-body" id="sellingView"></div>
	</div>
	<div id="itemDetailsView"></div>
</div>
