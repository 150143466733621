{{#if modal}}
<div class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
				<span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_JSP_AUCTION_INFORMATION}}}</h4>
		</div>
		<div class="modal-body ">
{{/if}}

			<div class="row w2-item-details-header">
				{{#unless modal}}
          <div class=" col-xs-offset-8 col-md-4 col-xs-3 w2-item-details-close-btn">
            <button type="button" class="btn btn-danger x-close-slide-menu pull-right">X</button>
          </div>
				{{/unless}}
			</div>
			<div class="w2-item-details-content">

				<div class="w2-item-details-tabs row">
					<!-- Desktop tabs - added unique ID's below for tests-->
					<div class="w2-itemdetail-icons col-lg-1 col-sm-2 hidden-xs">

						<ul class="nav nav-pills nav-stacked text-center" id="itemDetailTabs">
							<li class="active">
								<a id="itemDetailsInfoLink" href="#info" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsInfo">
									<span class="fa fa-info-circle"></span>
								</a>
							</li>
							{{#if theImages}}
								<li>
									<a id="itemDetailsImagesLink" href="#images" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsImages">
										<span class="fa fa-picture-o"></span>
									</a>
								</li>
							{{/if}}
							{{#if theVideos}}
								<li>
									<a id="itemDetailsVideoLink" href="#video" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsVideo">
										<span class="fa fa-file-video-o"></span>
									</a>
								</li>
							{{/if}}
							<li>
								<a id="itemDetailsLocationLink" href="#location" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsLocation">
									<span class="fa fa-map-marker"></span>
								</a>
							</li>
							{{#if theDocs}}
								<li>
									<a id="itemDetailsDocumentsLink" href="#documents" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsDocuments">
										<span class="fa fa-file-text-o"></span>
									</a>
								</li>
							{{/if}}
						</ul>

					</div>


					<!-- Mobile tabs - added unique ID's below for tests-->
					<div class="w2-itemdetail-icons col-xs-10 hidden-lg hidden-md hidden-sm margin-bottom">
						<ul class="nav nav-tabs" id="itemDetailTabs">
							<li class="active">
								<a id="mobileItemDetailsInfoLink" href="#info" class="x-change-tab-details" role="tab" data-toggle="tab">
									<span class="fa fa-info-circle"></span>
								</a>
							</li>
							{{#if theImages}}
                <li>
                  <a id="mobileItemDetailsImagesLink" href="#images" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsImages">
                    <span class="fa fa-picture-o"></span>
                  </a>
                </li>
							{{/if}}
              {{#if theVideos}}
                <li>
                  <a id="mobileItemDetailsVideoLink" href="#video" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsVideo">
                    <span class="fa fa-file-video-o"></span>
                  </a>
                </li>
							{{/if}}
							{{#if latLong}}
								<li>
									<a id="mobileItemDetailsLocationLink" href="#location" class="x-change-tab-details" role="tab" data-toggle="tab" data-action="itemDetailsLocation">
										<span class="fa fa-map-marker"></span>
									</a>
								</li>
							{{/if}}
							{{#if theDocs}}
                <li>
                  <a id="mobileItemDetailsDocumentsLink" href="#documents" class="x-change-tab-details" role="tab" data-toggle="tab"
                  data-action="itemDetailsDocuments">
                    <span class="fa fa-file-text-o"></span>
                  </a>
                </li>
							{{/if}}
						</ul>
					</div>

					<!-- Content -->
					<div class="col-sm-10 col-xs-12">

						<div class="tab-content w2-item-details-tabs-item">

							<div class="tab-pane active w2-itemdetail-tabcontent w2-tabs-saleinfo-scroll" id="info">
								<div class="row">
									<div class="col-xs-12 col-sm-6">
										<img class="w2-item-detail-info-image img-responsive margin-bottom" src="{{ imagePreviewSrc }}" alt="" onerror="myApp.utils.errors.ImgErrorHomeList(this)">
									</div>
								</div>
								<div class="row">
									<div class="col-xs-12">
										<h4>{{{lot}}} {{ lotNumber }}: {{{ title }}}</h4>
									</div>
								</div>
								<div class="row">
									<div class="col-xs-12">
										<div class="w2-item-details-summary">
											{{{ description }}}
										</div>
										<div class="w2-item-details-summary">
											{{{ summary }}}
										</div>
									</div>
								</div>
							</div>

							{{#if theImages}}
                <div class="tab-pane w2-tabs-saleinfo-scroll w2-tabs-scroll" id="images">
                  <div class="row">

                    {{#each theImages }}
                      <div class="col-xs-12 col-sm-6 margin-bottom">
                        <a href="{{imageLightboxSrc}}" data-toggle="lightbox" data-gallery="item-details-{{../lotNumber}}" data-title="Images for {{{../lot}}}-{{../lotNumber}}"
                        class="x-open-light-box w2-item-detail-image-lightbox">
                          <img class="w2-item-details-images img-responsive" src="{{ imagePreviewSrc }}" alt="" onerror="myApp.utils.errors.ImgErrorWebSaleInfo(this)">
                        </a>
                      </div>
                    {{/each}}

                  </div>
                </div>
							{{/if}}
              {{#if theVideos}}
                <div class="tab-pane w2-tabs-saleinfo-scroll w2-tabs-scroll" id="video">
                  <div class="w2-item-videos">
                    {{#each theVideos }}
                      <div class="row border-bottom">
                        <a href="{{link}}" target="_blank" data-gallery="youtubevideos" title="{{{label}}}" class="x-open-light-box w2-item-detail-image-lightbox">
                          <div class="col-md-1 col-sm-2 col-xs-3">
                            <i class="fa fa-file-video-o w2-item-video-icons"></i>
                          </div>
                          <div class="col-xs-6">
                            {{{label}}}
                          </div>
                        </a>
                      </div>
                    {{/each}}
                  </div>
                </div>
							{{/if}}

							{{#if latLong}}
								<div class="tab-pane w2-tabs-saleinfo-scroll" id="location">
									{{#if mapsApiKey}}
										<iframe
											id="mapCanvas"
											width="100%"
											height="450"
											frameborder="0"
											style="border:0"
											src="https://www.google.com/maps/embed/v1/place?key={{mapsApiKey}}&q={{latLong}}"
											allowfullscreen
										/>
										</iframe>
									{{/if}}
									<div class="w2-item-detail-map-address">
										{{{addressLines}}}</br> {{{city}}}</br> {{{county}}}</br> {{{country}}}</br> {{{postcode}}}
									</div>
								</div>
							{{/if}}

							{{#if theDocs}}
                <div class="tab-pane w2-tabs-saleinfo-scroll" id="documents">
                  <div class="w2-item-docs list-group">
                    {{#each theDocs }}
											<a href="{{docPreviewSrc}}" target="_blank" class="list-group-item">
												<span class="badge"><i class="fa fa-file-{{docIcon}}-o w2-item-doc-icons"></i></span>
												<span class="w2-item-doc-number">{{{docFileName}}}</span>
												{{#if label.length}}
													<p class="help-block">{{{label}}}</p>
												{{/if}}
											</a>
                    {{/each}}
                  </div>
                </div>
							{{/if}}

						</div>
					</div>
				</div>
			</div>

{{#if modal}}
		</div>
		<div class="modal-footer">
			<button type="submit" class="x-cancel btn btn-default">Close</button>
		</div>
	</div>
{{/if}}