<nav class="menu slide-menu-right {{wrapper}}">
	<div class="row sale-info-slide-header">
		<div class="col-md-8"> 
			<div class="text-left">
				<h2>{{{heading}}}</h2> 
			</div>
		</div>
		<div class="col-md-4">
			<button type="button" class="btn btn-danger x-close-slide-menu pull-right">Close &rarr;</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div id="{{slideContentId}}" class="sale-info-slide-content">
				{{{content}}}
			</div>
		</div>
	</div>
</nav>
