<div class="t2-auction-list-title">
  <h4>{{{title}}}</h4>
</div>

{{#upcomingFeaturedAuctions}}
  <div id="featured_{{@index}}">
    <div class="col-md-12">
      <div class="row">
        <div class="t2-auction-list col-md-12">
          <div class="row t2-images">
            <div class="col-md-12">
              <a class="x-auction" href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{title}}}</a>
            </div>
            <div class="col-md-12">
              <h6 class="pull-right text-right m-t-xs">{{{location}}}</h6>
            </div>
          </div>
          <p style="display:none;">{{{endsOrStarts}}}</p>
          <div class="row m-t-sm t2-register">
            <div class="col-md-8 col-xs-6 font-bold">{{{endsOrStarts}}}</div>
            <div id="registration_{{auctionId}}" class="col-md-4 col-xs-6 text-right font-bold"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
{{/upcomingFeaturedAuctions}}

{{#upcomingAuctions}}
  <div id="unfeatured_{{@index}}">
    <div class="col-md-12">
      <div class="row">
        <div class="t2-auction-list col-md-12">
          <div class="row t2-images">
            <div class="col-md-12">
              <a class="x-auction" href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{title}}}</a>
            </div>
            <div class="col-md-12">
              <h6 class="pull-right text-right m-t-xs">{{{location}}}</h6>
            </div>
          </div>
          <p style="display:none;">{{{endsOrStarts}}}</p>
          <div class="row m-t-sm t2-register">
            <div class="col-md-8 col-xs-6 font-bold">{{{endsOrStarts}}}</div>
            <div id="registration_{{auctionId}}" class="col-md-4 col-xs-6 text-right font-bold"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
{{/upcomingAuctions}}