<div class="container">
	<div class="inner-content-box">
		<div class="row">
			<div class="col-xs-12 col-md-8 col-md-offset-2">
				<h2>{{{JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD}}}</h2>
				<div>
					<div>
						{{#if validPasswordChange}}
							<div class="form-group">
								<label id="globalError" class="control-label"></label>
							</div>
							<div class="form-group">
								<label class="control-label" for="password">{{{JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD}}}</label>
								<input id="password" value="{{password}}" type="password" class="x-password form-control" maxlength="100"/>
								<span class="control-label" id="passwordErrors"></span>
							</div>
							<div class="form-group">
								<label for="passwordStrengthBar">{{{JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD_STRENGTH}}}</label>
								<div class="progress">
									<div id="complexity-bar" class="x-progress progress-bar" role="progressbar" style="color: #f00; width: 0%;"></div>
								</div>
							</div>
							<div class="form-group">
								<label class="control-label" for="passwordConfirm">{{{JspPublicCodes_JSP_CHANGE_PASSWORD_REPEAT_PASSWORD}}}</label>
								<input id="repeatPassword" value="{{passwordConfirm}}" type="password" class="x-confirm form-control" maxlength="100"/>
								<span class="control-label" id="passwordConfirmErrors"></span>
							</div>
							<input id="token" type="hidden" class="x-token" value="{{token}}" maxlength="100"/>
							<button id="submitButton" type="submit" class="x-submit btn btn-primary">{{{JspPublicCodes_JSP_CHANGE_PASSWORD}}}</button>
						{{else}}
							{{{JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD}}}
						{{/if}}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>