<li class="w2-sell-items-group w2-group-scroll list-group-item clearfix">
	<div class="row">
		{{#if groupMemberActive}}
			<div class="col-xs-1">
				<input type="checkbox" class="x-group-member-select pull-left" data-id="{{groupMemberId}}"/>
			</div>
		{{/if}}
		<div class="col-lg-2 col-md-3 col-xs-4">
			<a class="x-show-item-detail" href="#" data-id="{{groupMemberId}}">
				<img class="img-responsive" src="{{imageThumbSrc}}" alt="{{{lot}}} {{lotNumber}}: {{{lotTitle}}}" onerror="myApp.utils.errors.ImgErrorThumb(this)">
			</a>
		</div>
		{{#if groupMemberActive}}
			<div class="w2-sell-item-body col-lg-9 col-md-8 col-xs-7"></div>
		{{else}}
			<div class="w2-sell-item-body col-lg-10 col-md-9 col-xs-8"></div>
		{{/if}}
			<a class="x-show-item-detail" href="#" data-id="{{groupMemberId}}">
				<p>
					<strong class="media-heading w2-sell-item-heading">{{{lot}}} {{lotNumber}}:</strong> <span {{displayUnits}}>({{itemNumberOfUnits}} items)</span>
					{{{lotTitle}}}
				</p>
			</a>
			<span>{{{lotSummary}}}</span>
		</div>
	</div>
</li>