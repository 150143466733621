{{#if showFilters}}
	<div class="row">
		<div class="col-xs-12 margin-bottom">
			<div class="pull-left">
				<div class="btn-group">
					{{#if showAuctions}}
						<button type="button" id="switch--auction" class="btn btn-default x-type {{#if isAuctionTypeActive}}active{{/if}}" data-type="auctions"><a href="#">{{{auctionsTab}}}</a></button>
					{{/if}}
					{{#if showMarketplace}}
						<button type="button" id="switch--marketplace" class="btn btn-default x-type {{#if isMarketplaceTypeActive}}active{{/if}}" data-type="marketplace"><a href="#">{{{marketplaceTab}}}</a></button>
					{{/if}}
				</div>
			</div>
			{{#if showLayoutToggle}}
				<ul class="nav nav-tabs hidden-xs hidden-sm">
					<li id="toggle-layout--gallery" class="{{#if isGridLayoutActive}}active{{/if}} pull-right"><a href="" role="presentation" class="x-layout" data-layout="gallery"><i class="fa fa-th"></i> {{{galleryView}}}</a></li>
					<li id="toggle-layout--list" class="{{#if isListLayoutActive}}active{{/if}} pull-right"><a href="" role="presentation" class="x-layout" data-layout="list"><i class="fa fa-list-ul"></i> {{{listView}}}</a></li>
					{{#if showCountries}}
						<li id="country-selector" class="dropdown pull-right"><a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{currentCountry}} <span class="caret"></span></a>
							<ul class="dropdown-menu" style="z-index: 100">
								<li id="country-selector--all"><a href="#" class="x-country" data-country="all">{{{allCountriesText}}}</a></li>
								{{#countries}}
									<li id="country-selector--{{this}}"><a href="#" class="x-country" data-country="{{this}}">{{{this}}}</a></li>
								{{/countries}}
							</ul>
						</li>
					{{/if}}
				</ul>
			{{/if}}
		</div>
	</div>
{{/if}}
{{#if isEmpty}}
	{{{emptyView}}}
{{/if}}
