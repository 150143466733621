<div>
    <div class="auction-auctioneer-banner row">
        <div class="auction-auctioneer-logo col-md-3">
            <div>
            	{{JspPublicCodes_JSP_AUCTIONEER_STRIP_SALEMGTBY}}
            </div>
            <div class="aadetails-image-wrapper">
                <a href="{{contextPath}}/auctioneer?auctioneerId={{auctioneerId}}">
                    <img src="{{logo}}" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)" alt="{{{auctioneerName}}}">
                </a>
            </div>
        </div>
    	<div class="auction-auctioneer-details col-md-9">
            <div class="aadetails-title">{{{JspPublicCodes_JSP_AUCTIONEER_STRIP_ENQUIRIES}}}</div>
            <div class="aadetails-name"><a href="{{contextPath}}/auctioneer?auctioneerId={{auctioneerId}}">{{{auctioneerName}}}</a></div>
            <div class="aadetails-item">{{{JspPublicCodes_JSP_SHORT_TELEPHONE}}}: {{contactNumber}}</div>
            <div class="aadetails-item">{{{JspPublicCodes_JSP_EMAIL}}}: <a href="mailto:{{auctioneerEmail}}">{{{auctioneerEmail}}}</a></div>
            <div class="aadetails-item">{{{JspPublicCodes_JSP_WEBSITE}}}: <a href="http://{{auctioneerWebsite}}">{{{auctioneerWebsite}}}</a></div>
        </div>
    </div>
</div>
