<div class="row">
	<div class="col-md-{{homeCalToggle}} col-sm-12 col-xs-12">
		<div class="row">
			<div class="col-md-12"><h4>{{{featuredMessage}}}</h4></div>
		</div>
		{{#upcomingFeaturedAuctions}}
		<div id="featured_{{@index}}" class="t2-auction-list col-md-12">
			<div class="col-md-2 col-sm-3 col-xs-12 text-center">
				<a href="{{../contextPath}}/auctionDetails/{{auctionId}}">


            	{{#if auctionLogo}}
 					<span><img class="img-responsive" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)" src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{name}}" /></span>
				{{else if auctioneerLogo}}
 					<span><img class="img-responsive" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)" src="{{#cloudify auctioneerLogo ../logoDims}}{{/cloudify}}" alt="{{name}}" /></span>
				{{else}}
					<span class="img-error-auctioneer-home"><div>{{{../awaitingImageMessage}}}</div></span>
				{{/if}}
				</a>
			</div>
			<div class="col-md-10 col-sm-9">
				<div class="row">
					<div class="col-md-12">
                        <h5 class="font-bold m-t-0 auctioneer-center-text">
							{{#if webcastType}}<i class="fa fa-wifi font-bold"></i>{{/if}} {{#if timedType}}<i class="fa fa-clock-o font-bold"></i>{{/if}} <a id="auctionDetails{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{auctioneerName}}} - {{{title}}} <span id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</span></a>
						</h5>
						<p class="t2-auctioneer-desc auctioneer-center-text">{{{description}}}</p>
						<div class="row">
							<div class="col-md-8 font-bold col-xs-12 auctioneer-center-text">{{endsOrStarts}}</div>
							<div id="registration_{{auctionId}}" class="col-md-4 col-sm-6 col-xs-12 pull-right text-right font-bold auctioneer-center-text"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{{/upcomingFeaturedAuctions}}
		<div class="row">
			<div class="col-md-12"><h4>{{{unfeaturedMessage}}}</h4></div>
		</div>
		{{#upcomingAuctions}}
		<div id="unfeatured_{{@index}}" class="t2-auction-list col-md-12">
			<div class="col-md-2 col-sm-3 col-xs-12 text-center">
				<a href="{{../contextPath}}/auctionDetails/{{auctionId}}">
            		{{#if auctionLogo}}
 						<span><img class="img-responsive" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)" src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{name}}" /></span>
            		{{else if auctioneerLogo}}
 						<span><img class="img-responsive" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)" src="{{#cloudify auctioneerLogo ../logoDims}}{{/cloudify}}" alt="{{name}}" /></span>
					{{else}}
						<span class="img-error-auctioneer-home"><div>{{{../awaitingImageMessage}}}</div></span>
					{{/if}}
				</a>
			</div>
			<div class="col-md-10 col-sm-9">
				<div class="row">
					<div class="col-md-12">
						<h5 class="font-bold m-t-0 auctioneer-center-text">
							{{#if webcastType}}<i class="fa fa-wifi font-bold"></i>{{/if}} {{#if timedType}}<i class="fa fa-clock-o font-bold"></i>{{/if}} <a id="auctionDetails{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{auctioneerName}}} - {{{title}}} <span id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</span></a>
						</h5>
						<p class="t2-auctioneer-desc auctioneer-center-text">{{{description}}}</p>
						<div class="row">
							<div class="col-md-8 col-xs-12 font-bold auctioneer-center-text">{{endsOrStarts}}</div>
							<div id="registration_{{auctionId}}" class="col-md-4 col-sm-6 col-xs-12 pull-right text-right font-bold auctioneer-center-text"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{{/upcomingAuctions}}
	</div>

    {{#if isAuctioneerHome}}
	<div class="col-md-3 col-sm-4 col-xs-12 auctioneer-home">
        <div class="auctioneers-heading">
            <h5><a href="{{contextPath}}/auctioneers">Auctioneers <span class="pull-right">See all…</span></a></h5>
        </div>
		{{#liveAuctioneers}}
		<div class="text-center t2-auctioneer">
			<div class="col-md-12 col-xs-12 t2-auction-list">
				<div class="row">
					<div class="col-md-12 col-sm-12 col-xs-12 text-center">
						<p><a href="{{../contextPath}}/auctioneer?auctioneerId={{auctioneerId}}">
	            			{{#if logo}}
								<span><img onerror="myApp.utils.errors.ImgErrorHomeAuctioneerLg(this)" class="img-responsive t2-auctioneer-img" src="{{#cloudify cloudyLogo ../logoDimsLg}}{{/cloudify}}" alt="{{name}}" /></span>
							{{else}}
								<span class="img-error-auctioneer-home-lg"><div>{{{../awaitingImageMessage}}}</div></span>
							{{/if}}
						</a></p>
						<h6 class="font-bold">{{{name}}}</h6>
					</div>
				</div>
			</div>

		</div>
		{{/liveAuctioneers}}
	</div>
    {{/if}}
</div>
