{{#if messageText.length}}
    <div id="auctionMessage" class="popover fade in bottom w2-message-auction-level">
        <div class="arrow"></div>
        <div class="popover-content">
            <button type="button" class="close pull-right" data-dismiss="popover" aria-label="Close"><span aria-hidden="true">×</span></button>
            <span>{{{messageText}}}</span>
        </div>
    </div>
{{/if}}

