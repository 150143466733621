<div class="row margin-top margin-bottom">
    {{#if showFeaturedAuctions}}
        <div class="t2-auction-list-title col-xs-12">
            <h4>{{{featuredAuctionsTitle}}}</h4>
        </div>

        {{#upcomingFeaturedAuctions}}
            <div id="featured_{{@index}}" class="col-xs-12 ">
                <div class="panel panel-default list-item--container">
                    <div class="panel-body row list-item list-item--featured">
                        <div class="list-item--thumbnails col-xs-12 col-sm-5">
                            {{#if hasLogo}}
                                <div class="list-thumbnail text-center">
                                    <a href="{{../contextPath}}/auctionDetails/{{auctionId}}">
                                        <img class="img-responsive" src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{{title}}}" />
                                    </a>
                                </div>
                            {{/if}}
                            {{#upcomingItems}}
                                <div class="list-thumbnail text-center">
                                    <a href="{{../../contextPath}}/auctionDetails/{{../auctionId}}">
                                        {{#if attachmentModel}}
                                            <img class="img-responsive" src="{{#cloudify attachmentModel ../../dims}}{{/cloudify}}" alt="{{{title}}}" />
                                        {{else}}
                                            <div class="img-error-list-home--thumbnail">{{{../../awaitingImageMessage}}}</div>
                                        {{/if}}
                                    </a>
                                </div>
                            {{/upcomingItems}}
                        </div>
                        <div class="list-item--content col-xs-12 col-sm-5">
                            <div class="list-item--grow list-item--content-info">
                                <h6 class="pull-right text-right">{{{location}}}</h6>
                                <a id="auctionDetails{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}"><h4>{{{title}}}</h4></a>
                                <h5 id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</h5>
                                {{#if startTimeString}}<p><strong>{{{startTimeString}}}</strong></p>{{/if}}
                                <p><strong>{{{endsOrStarts}}}</strong></p>
                                <p>{{{description}}}</p>
                            </div>
                            <div id="registration_{{auctionId}}" class="list-item--no-grow list-item--content-actions text-right margin-top">
                                {{#if catalogue}}
                                    <a id="auctionDetailsCatalogueLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
                                        <button id="catalogueAuction{{auctionId}}" class="btn btn-primary btn-block">{{{../catalogueAuctionMessage}}}</button>
                                    </a>
                                {{else}}
                                    {{#if registrantStatus}}
                                        <button id="auctionstatus{{auctionId}}" class="register-forauction btn btn-primary btn-block" disabled="true">{{{lookup ../auctionRegistrantStatusMessage registrantStatus}}}</button>
                                    {{else}}
                                        <a class="register-for-auction" id="registerForAuctionFromAuction{{auctionId}}" href="{{../contextPath}}/registerForAuction/{{auctionId}}">
                                            <button class="btn btn-primary btn-block">{{{../auctionRegistrantStatusMessage.NOT_REGISTERED}}}</button>
                                        </a>
                                    {{/if}}
                                {{/if}}
                            </div>
                        </div>
                        <div class="list-item--labels">
                            <div class="label label-info">
                                {{#if webcastType}}<i class="fa fa-wifi"></i>{{/if}}
                                {{#if timedType}}<i class="fa fa-clock-o"></i>{{/if}} {{{typeMessage}}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {{/upcomingFeaturedAuctions}}
    {{/if}}

    <div class="t2-auction-list-title col-xs-12">
        <h4>{{{auctionsTitle}}}</h4>
    </div>

    {{#upcomingAuctions}}
        <div id="unfeatured_{{@index}}" class="col-xs-12">
            <div class="panel panel-default list-item--container">
                <div class="panel-body row list-item list-item--unfeatured">
                    <div class="list-item--thumbnails col-xs-12 col-sm-5">
                        {{#if hasLogo}}
                            <div class="list-thumbnail text-center">
                                <a href="{{../contextPath}}/auctionDetails/{{auctionId}}">
                                    <img class="img-responsive" src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{{title}}}" />
                                </a>
                            </div>
                        {{/if}}
                        {{#upcomingItems}}
                            <div class="list-thumbnail text-center">
                                <a href="{{../../contextPath}}/auctionDetails/{{../auctionId}}">
                                    {{#if attachmentModel}}
                                        <img class="img-responsive" src="{{#cloudify attachmentModel ../../dims}}{{/cloudify}}" alt="{{{title}}}" />
                                    {{else}}
                                        <div class="img-error-list-home--thumbnail">{{{../../awaitingImageMessage}}}</div>
                                    {{/if}}
                                </a>
                            </div>
                        {{/upcomingItems}}
                    </div>
                    <div class="list-item--content col-xs-12 col-sm-5">
                        <div class="list-item--grow list-item--content-info">
                            <h6 class="pull-right text-right">{{{location}}}</h6>
                            <a id="auctionDetails{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}"><h4>{{{title}}}</h4></a>
                            <h5 id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</h5>
                            {{#if startTimeString}}<p><strong>{{{startTimeString}}}</strong></p>{{/if}}
                            <p><strong>{{{endsOrStarts}}}</strong></p>
                            <p>{{{description}}}</p>
                        </div>
                        <div id="registration_{{auctionId}}" class="list-item--no-grow list-item--content-actions text-right margin-top">
                            {{#if catalogue}}
                                <a id="auctionDetailsCatalogueLink{{auctionId}}" href="{{../contextPath}}/auctionDetails/{{auctionId}}">
                                    <button id="catalogueAuction{{auctionId}}" class="btn btn-primary btn-block">{{{../catalogueAuctionMessage}}}</button>
                                </a>
                            {{else}}
                                {{#if registrantStatus}}
                                    <button id="auctionstatus{{auctionId}}" class="register-forauction btn btn-primary btn-block" disabled="true">{{{lookup ../auctionRegistrantStatusMessage registrantStatus}}}</button>
                                {{else}}
                                    <a class="register-for-auction" id="registerForAuctionFromAuction{{auctionId}}" href="{{../contextPath}}/registerForAuction/{{auctionId}}">
                                        <button class="btn btn-primary btn-block">{{{../auctionRegistrantStatusMessage.NOT_REGISTERED}}}</button>
                                    </a>
                                {{/if}}
                            {{/if}}
                        </div>
                    </div>
                    <div class="list-item--labels">
                        <div class="label label-info">
                            {{#if webcastType}}<i class="fa fa-wifi"></i>{{/if}} {{#if timedType}}<i class="fa fa-clock-o"></i>{{/if}} {{{typeMessage}}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {{/upcomingAuctions}}
</div>
