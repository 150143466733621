<div class="container">
	<div class="row">
		<div class="col-xs-12">
			<h1>
				{{{JspPublicCodes_JSP_APEX_HAS_SENT_EMAIL}}}
			</h1>
			<p>
				{{{JspPublicCodes_JSP_CLICK_LINK_TO_CHANGE}}}
			</p>
			<a href="{{backToHomepageUrl}}"><button class="btn btn-primary">{{{JspPublicCodes_JSP_BACK_TO_HOMEPAGE}}}</button></a>
		</div>
	</div>
</div>