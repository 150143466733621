
<div class="container-fluid">
	<div class="row">
		<div class="col-xs-12 col-md-8 col-md-offset-2">
			<h2>{{{JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD}}}</h2>
			<p>{{{JspPublicCodes_JSP_PASSWORD_ALREADY_CHANGED}}}</p>
			<p>
				{{{JspPublicCodes_JSP_NEED_CHANGE_AGAIN}}} (<a href="{{contextPath}}/forgottenPassword">{{{JspPublicCodes_JSP_NEW_PASSWORD_CHANGE_REQUEST}}}</a>).
			</p>
			<a class="button button-primary" href="{{contextPath}}/login"><span>{{{JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD_LOGIN}}}</span></a>
		</div>
	</div>
</div>
