<div id="bidBtn" class="w2-bidview-box text-center">
	<div class="col-xs-12">
		<button id="inPlayBidButton" class="x-bid-action btn {{buttonClass}} btn-lg btn-block" data-action="{{buttonAction}}" data-amount="{{bidAmount}}" type="button" {{buttonState}}>
			{{{buttonLabel}}}
			{{#if bidCalculation}}
				<span class="bid-calculation"></br>[{{{bidCalculation}}}]</span>
			{{/if}}
		</button>
	</div>
	<div class="w2-highbid-inplay col-xs-12 margin-top">
		{{{currentBidMessage}}} <strong>{{{currentFormatted}}}</strong> {{ origin }} {{{currentBidCount}}}
	</div>
</div>
