<div class="container">
	<div class="inner-content-box">
		<div class="row">
			<div class="col-xs-12 col-md-8 col-md-offset-2">
				<h2>
					{{{JspPublicCodes_JSP_PASSWORD_CHANGED}}}
				</h2>
				<div>
					<p>
						{{{JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL}}}
					</p>
					<a href="{{loginUrl}}"><button class="btn btn-primary" >{{{JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL_LOGIN}}}</button></a>
				</div>
			</div>
		</div>
	</div>
</div>