<div class="container">
	<div class="row">
		<div class="form-horizontal col-xs-12">
			<h1>
				{{#if resetPasswordMode}}
					{{{JspPublicCodes_JSP_RESET_PASSWORD}}}
				{{else}}
					{{{JspPublicCodes_JSP_FORGOTTEN_PASSWORD}}}
				{{/if}}
			</h1>
			<p>{{{JspPublicCodes_JSP_PASSWORD_RESET_INSTRUCTIONS}}}</p>
			<div id="globalError" class="control-label"></div>
			<div class="form-group">
				<div class="col-xs-12">
					<label class="sr-only">Email Address</label>
					<input value="{{email}}" id="email" type="email" class="x-email form-control" maxlength="120" placeholder="{{{JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ADDRESS}}}"/> 
					<span class="control-label" id="emailErrors"></span>
				</div>
			</div>
			<div class="form-group">
				<div class="col-xs-12 text-right">
					<button id="submitButton" type="submit" class="x-submit btn btn-primary btn-lg">{{{JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ME}}}</button>
				</div>
			</div>
		</div>
	</div>
</div>