	<div id="messageRegion"></div>
	<div class="container">
		<div class="inner-box">
			<div id="titleRegion"></div>
			<div class="row">
				<div class="w2-image-container col-md-5 col-xs-12">
					<div id="webcastRegion"></div>
				</div>
				<div class="w2-tabs-container col-md-7 col-xs-12">
					<div id="w2VideoRegion"></div>
					<div id="w2ItemsRegion"></div>
				</div>
			</div>
		</div>
	</div>
	<div id="myModalRegion"></div>
